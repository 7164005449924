.plans{
    display: flex;
    gap: 3rem;
    justify-content: center;
    margin-top: 80px;
}
.plan-card{
    background: var(--darkGrey);
    padding: 30px 30px;
}
.plan-card:hover{
    background: var(--planCard);
}
.plan-card:hover svg{
    fill: var(--white);
}
.plan-card:hover button{
    color: var(--orange);
}
.plan-feature{
    list-style: none;
    padding-left: 0px;;
}
.plan-feature li{
    margin: 10px 0px;
}
.plan-card:nth-child(2)
{
    background: var(--planCard);
    transform: scale(1.1);
}
.plan-card svg{
    width: 3rem;
    height: 3rem;
    fill: var(--orange);
}
.plan-heading{
    font-size: 22px;
    margin: 30px 0px;
}
.plan-price{
    font-size: 46px;
    margin: 30px 0px;
}
.plan-feature li{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 20px 0px;
}
.plan-feature li img{
    width: 20px;
}
.btn-join{
    background-color: #fff;
    border: 4px solid transparent;
    font-weight: 700;
    padding: 0.5rem;
    transition: all .3s;
    width: 100%;
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
}
.plan-card:nth-child(2) .btn-join{
    color: var(--orange);
}
.plan-card:nth-child(2) svg{
    fill: var(--white);
}
.plan-blur-1{
    height: 30rem;
    left: 8rem;
    width: 22rem;
}
.plan-blur-2{
    height: 30rem;
    right: 8rem;
    width: 22rem;
}
@media only screen and (max-width: 768px)
{
    .plans{
        flex-direction: column;
        gap: 1rem;
        margin-top: 0px;
    }
    .plan-card:nth-child(2)
    {
        transform: scale(1);
    }
    .plan-heading{
        margin: 10px 0;
    }
    .plan-price{
        font-size: xx-large;
        margin: 10px 0;
    }
    .plan-card{
        padding: 20px;
    }
    .plan-card svg{
        width: 2rem;
    }
    .plan-feature li{
        margin: 10px 0px;
    }
    .plan-blur-1{
        height: 20rem;
        left: 0rem;
        width: 12rem;
    }
    .plan-blur-2{
        display: none;
    }
}