.contact-section{
    display: flex;
    gap: 3rem;
    position: relative;
}
.contact-left{
    flex: 1;
    position: relative;
}
.contact-right{
    flex: 1;
    position: relative;
}
form{
    background: transparent;
    border:1px solid var(--white);
    padding: 30px;
    border-radius: 15px;
}
.form-group{
    margin: 15px 0px;
}
.form-control{
    width: 100%;
    height: 30px;
}
textarea{
    width: 100%;
    height: auto;
}
.contact-section hr{
    width: 5rem;
    border: 2px solid var(--orange);
    position: absolute;
    top: 0;
    left: 0;
}
.contact-section .section-title{
    margin-top: 15px;
}
.contact-details p{
    display: flex;
    gap: 1rem;
    align-items: center;
}
.contact-details p span:first-child{
    background: var(--white);
    border-radius: 50%;
    padding: 10px;
}
.contact-details img{
    width: 1.5rem;
}
.contact-details p span:last-child{
    font-size: 20px;
}
.contact-details a{
    text-decoration: none;
    color: var(--white);
}
@media only screen and (max-width: 768px)
{
    form{
        padding: 10px 15px;
    }
    .contact-section{
        flex-direction: column;
    }
    .contact-details p{
        gap: 0.5rem;
    }
    .contact-details img{
        width: 1rem;
    }
    .contact-details p span:last-child{
        font-size: 16px;
    }
}