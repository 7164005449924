.newsletter-section{
    display: flex;
    align-items: end;
    position: relative;
}
.newsletter-section hr{
    border:2px solid var(--orange);
    width: 8rem;
    position: absolute;
    top: 1.5rem;
} 
.newsletter-section .section-title{
    margin-bottom: 0px;
    width: 90%;
}
.newsletter-left{
    flex: 1;
}
.newsletter-right{
    flex: 1;
}
.newsletter-email{
    background: var(--darkGrey);
    padding: 30px;
    width: fit-content;
    width: 60%;
    display: flex;
    justify-content: space-around;
}
.newsletter-email input{
    background: transparent;
    border:0px;
    color: var(--white);
    width: 50%;
}
.newsletter-email input:focus{
    outline: none;
}
.newsletter-email button{
    margin-left: 3rem;
}
@media only screen and (max-width: 768px)
{
    .newsletter-section{
        margin-top: 100px;
        flex-direction: column;
        align-items: start;
    }
    .newsletter-section .section-title{
        width: 100%;
    }
    /* .newsletter-email{
        width: 100%;
        flex-direction: column;
        justify-content: start;
        margin-top: 20px;
        padding: 10px;
    } */
    .newsletter-email{
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }
    .newsletter-email input{
        width: 100%;
    }
    .newsletter-email button{
        margin-top: 20px;
        margin-left: 0px;
    } 
}