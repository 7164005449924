.hero-section{
    display: flex;
    color:#fff;
}
.hero-blur {
    height: 30rem;
    left: 0;
    width: 22rem;
}
.blur {
    background: rgba(253,120,43,.69);
    border-radius: 50%;
    -webkit-filter: blur(212px);
    filter: blur(212px);
    -webkit-filter: blur(150px);
    filter: blur(150px);
    position: absolute;
    z-index: -9;
}
.hero-left{
    flex:3 1;
    padding: 10px 30px;
    margin-top: 50px;
}
.hero-right{
    flex: 1;
    background-color: var(--orange);
    position: relative;
}
.hero-section .highlight {
    background-color:var(--darkGrey);
    color: #fff;
    text-transform: uppercase;
    width: fit-content;
    padding: 20px 13px;
    border-radius: 35px;
    position: relative;
}
.highlight span{
    position: relative;
    z-index: 2;
    letter-spacing: 1px;
    font-weight: 600;
}
.highlight-mark{
    position: absolute;
    width: 105px;
    height: 40px;
    top: 9px;
    left: 6px;
    border-radius: 25px;
    z-index: 1;
    background-color: var(--orange);
}
.hero-title{
    text-transform: uppercase;
    font-size: 75px;
    color:#fff
}
.hero-text{
    font-size: 20px;
}
.hero-parameter{
    display: flex;
    gap: 2rem;
    text-align: center;
    margin-top: 40px;
}
.parameter-number{
    font-size: 36px;
    margin: 0px;
    font-weight: 800;
}
.parameter-title{
    text-transform: uppercase;
    font-size: 18px;
    margin: 10px 0px 0px 0px;
    color:#999999;
}
.hero-button{
    margin-top: 30px;
    display: flex;
    gap: 1rem;
}
.hero-image{
    position: absolute;
    right: 8rem;
    top: 10rem;
    width: 23rem;
}
.hero-backimage {
    position: absolute;
    right: 20rem;
    z-index: -1;
    width: 15rem;
    top: 3rem;
}
.heart-card{
    background: var(--darkGrey);
    padding:15px 20px;
    width: fit-content;
    position: absolute;
    right: 3rem;
    top: 3rem;
    border-radius: 15px;
}
.heart-card .parameter{
    font-size: 18px;
    margin: 5px;
}
.heart-card h5{
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 0px;
}
.heart-card img{
    width: 30px;
}
@media only screen and (max-width: 768px) {
    .hero-section{
        flex-direction: column;
    }
    .hero-left{
        padding: 10px;
        margin-top: 20px;
    }
    .hero-section .highlight {
        padding: 10px 10px;
    }
    .highlight-mark{
        height: 22px;
    }
    .highlight span{
        font-size: x-small;
        scale: 0.8;
        text-align: center;
    }
    .hero-title{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
    }
    .hero-text{
        margin-top: 0px;
        font-size: small;
        text-align: center;
    }
    .hero-parameter .parameter-number{
        font-size: large;
    }
    .hero-parameter .parameter-title{
        font-size: small;
    }
    .hero-button{
        justify-content: center;
    }
    .hero-right{
        background: none;
    }
    .heart-card{
        position: relative;
        left: 1rem;
        right: 1rem;
        padding: 15px;
    }
    .heart-card img{
        width: 20px;
    }
    .heart-card h5{
        font-size: 16px;
    }
    .hero-image{
        width: 11rem;
        height: 17rem;
        right: 0rem;
        top: 1rem;
    }
    .hero-backimage{
        width: 5rem;
        top: 8rem;
        left: 6rem;
        height: 9rem;
    }
    .hero-blur{
        height: 20rem;
    left: 0;
    width: 12rem;
    }
}