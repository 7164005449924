:root{
  --primary-color:#f48915;
  --btn-primary:#f48915;
  --btn-white:#fff;
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg,#fa5042 1.14%,#ffa739 100.75%);
  --appColor: #3c3f45;
  --white:#fff;
  --black:#000;
}
body{
  background-image: url('assets/noise.png');
  background-color: var(--appColor);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode:multiply;
  color: #fff;
}
.primary-color{
  color: var(--orange);
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
svg{
  fill: #fff;
}
.stroke{
  color: transparent;
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #fff;
}
.btn-primary{
  background:var(--btn-primary);
  padding: 10px 15px;
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
  border:0px;
}
.btn-transparent{
  background-color: transparent;
  border:2px solid var(--orange);
  padding: 10px 15px;
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
}
.section{
  margin: 100px 30px;
}
.section-title{
  font-size: 50px;
  text-transform: capitalize;
  color: #fff;
  margin:40px 0px;
}
.pt50{
  padding-top: 50px;
}
.pt30{
  padding-top: 30px;
}
.short-hint{
  text-transform: uppercase;
  font-size: 18px;
  color: var(--orange);
}
.p-0{
  padding: 0px;
}
.m-0{
  margin: 0px;
}
@media only screen and (max-width: 768px)
{
  .section{
    margin: 50px 20px;
  }
  .section-title{
    font-size: xx-large;
  }
  .short-hint{
    margin: 20px 0px 10px;
  }
}