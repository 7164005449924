.why-choose-section{
    display: flex;
    gap: 2;
}
.choose-left{
    grid-gap: 1rem;
    display: grid;
    flex: 1 1;
    gap: 1rem;
    /* grid-auto-rows: 1fr; */
    grid-template-columns: repeat(3,1fr);
}
.choose-left img{
    object-fit: cover;
}
.choose-left img:first-child{
    grid-row: 1/3;
    height: 28rem;
    width: 12rem;
}
.choose-left img:nth-child(2)
{
    grid-column: 2/4;
    width: auto;
    height: 16rem;
}
.choose-left img:nth-child(3)
{
    grid-row: 2;
    grid-column: 2/3;
    width: 14rem;
}
.choose-left img:nth-child(4)
{
    grid-row: 2;
    grid-column: 3/4;
    height: 11.2rem;
    width: 10rem;
}
.choose-right{
    flex: 1 1;
}
.why-choose-section .section-title{
    margin: 0px;
}
.choose-list{
    display: flex;
    align-items: center;
    margin: 20px;
}
.choose-list img{
    width: 30px;
    margin-right: 15px;
}
.choose-list span{
    font-size: 18px;
}
@media only screen and (max-width: 768px)
{
    .why-choose-section{
        flex-direction: column;
    }
    .choose-left{
        grid-auto-rows: auto;
        overflow: hidden;
        gap: 1rem;
        
    }
    .choose-left>img:first-child{
        width: 6rem;
        height: 16rem;
    }
    .choose-left img:nth-child(2)
    {
        width: 10rem;
        height: 8rem;
    }
    .choose-left img:nth-child(3)
    {
        width: 3rem;
        height: 7rem;
    }
    .choose-left img:nth-child(4)
    {
        width: 4rem;
        height: 7rem;
    }
    .choose-list{
        margin: 10px 0px;
    }
    .choose-list img{
        width: 1.5rem;
    }
    .choose-list span{
        font-size: small;
    }
}