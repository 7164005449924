.footer{
    text-align: center;
}
.footer-logo{
    font-size: 50px;
    margin: 30px;
}
.footer-social{
    list-style: none;
    display: flex;
    justify-content: center;
    padding-left: 0px;
}
.footer-social li{
    padding: 0px 15px;
}
.footer-social li img{
    width: 20px;
}
.footer-menu{
    list-style: none;
    display: flex;
    justify-content: center;
    padding-left: 0px;
    margin-top: 20px;
}
.footer-menu li{
    padding: 0px 15px;
    border-right: 1px solid var(--white);
}
.footer-menu li:last-child{
    border-right: 0px;
}
.footer-bottom{
    box-shadow: 0px 0px 20px #5a5a5a; 
    display: flex;  
    margin-top: 3rem; 
    padding: 0px 20px;
}
.footer-bottom p{
    flex: 1;
}
.footer-bottom p a{
    text-decoration: none;
    color: var(--orange);
}
.footer-terms{
    flex: 1;
    list-style: none;
    display: flex;
    justify-content: end;
}
.footer-terms li{
    padding: 0px 15px;
    border-right: 1px solid var(--white);
}
.footer-terms li:last-child{
    border-right: 0px;
}
.footer-blur{
    height: 10rem;
    width: 20rem;
    right: 38rem;
}
.footer-social li{
    border-right: 1px solid #fff;
}
.footer-social li:last-child{
    border-right: 0px;
}
@media only screen and (max-width: 768px)
{
    .footer-section{
        margin: 0px;
        text-align: center;
    }
    .footer-menu{
        font-size: x-small;
    }
    .footer-bottom{
        font-size: x-small;
        flex-direction: column;
    }
    .footer-terms{
        padding: 0px;
    }
    .footer-terms li{
        padding:0px 5px;
    }
}