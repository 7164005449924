.menu{
    display: flex;
    justify-content: space-between;
    padding:5px 30px;
    align-items: center;
    background-color: var(--darkgrey);
    color:#fff;
    box-shadow: 0px 0px 20px #575757;
}
.left-menu{
    flex:1;
}
.right-menu{
    flex:1;
}
.menu-brand{
    font-size: 24px;
}
.menu-list{
    list-style: none;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: end;
}

.menu-brand a, .right-menu .menu-list li a{ 
    text-decoration: none;
    color: #fff;
}
@media only screen and (max-width: 768px)
{
    .right-menu{
        position: fixed;
        right: 1rem;  
        top: 3rem;      
        z-index: 99;
    }
    .menu-list{
        padding: 0px;        
        background: var(--appColor);
        flex-direction: column;
        gap: 0rem;
        padding: 0px 5px 0px;
        align-items: start;
    }
    .right-menu .menu-list li{        
        padding: 10px;
        margin: 0px;
        padding-bottom: 10px;
    }
    .right-menu .menu-list li:last-child{
        border: 0px;
    }
    .right-menu .bar-image{
        width: 2rem;
        background: var(--appColor);
        padding: 10px;
        margin-top: -1rem;
    }
    .right-menu .close-menu{
        width: 1rem;
        background: var(--appColor);
        padding: 10px;
        margin-top: -1rem;
    }
    .right-menu .close-menu{
        position: absolute;
        right: 0rem;
        top: 0rem;
    }
}