.innerhero-section{
    display: flex;
    gap: 2rem;
}
.innerhero-left{
    flex:3;
    padding: 10px 30px;
    margin-top: 30px;
}
.innerhero-right{
    flex: 1;
    background-color: var(--orange);
    position: relative;
}
.hero-content{
    width: 60%;
}
.innerhero-left .hero-title{
    text-transform: uppercase;
    font-size: 75px;
    color:#fff;
    margin-bottom: 20px;
}
.hero-text{
    font-size: 20px;
}
.innerhero-image{
    position: absolute;
    right: 10rem;
    bottom: -2rem;
    width: 17rem;
}
.innerhero-backimage{
    position: absolute;
    right: 18rem;
    z-index: -1;
    width: 8rem;
    top: 9rem;
}