.special-space{
    padding: 0 40px;
}
.category{
    display: flex;
    gap: 2rem;
}
.program-card{
    padding: 30px 25px;
    background-color: var(--darkGrey);
}
.program-card:hover{
    background: var(--planCard);
}
.program-icon{
    margin-bottom: 5px;
}
.program-card svg{
    height: 2rem;
    width: 2rem;
    margin-bottom: 10px;
}
.program-heading{
    font-size: 22px;
    margin: 10px 0px;
}
.program-detail{
    margin-top: 10px;
}
.learn-more{
    display: flex;
    align-items: center;
    margin-top: 25px;
}
.learn-more a{
    color: #fff;
    text-decoration: none;
}
.learn-more img{
    width: 15px;
    padding-left: 10px;
}
@media only screen and (max-width: 768px)
{
    .program-section{
        margin-top: 150px;
    }
    .special-space{
        padding: 8px;
    }
    .category{
        flex-direction: column;
        gap: 1rem;
    }
}