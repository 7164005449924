.testimonials-section{
    display: flex;
    margin-top: 150px;
}
.testimonials-left{
    flex: 1;
}
.testimonials-right{
    flex: 1;
    position: relative;
}
.testimonial-review{
    letter-spacing: 3px;
    line-height: 35px;
}
.testimonial-name{
    text-transform: uppercase;
    color: var(--orange);
}
.testimonial-status{
    text-transform: uppercase;
}
.testimonial-footer{
    margin-top: 30px;
}
.testimonials-arrow{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
}
.testimonials-arrow img{
    width: 25px;
    gap: 1rem;
}
.testimonials-image{
    position: absolute;
    right: 10rem;
}
.testimonials-image img{
    width: 17rem;  
    height: 25rem;  
    z-index: 2;
}
.testimonials-image .testimonial-border{
    border:2px solid var(--orange);
    width: 17rem;
    background: transparent;
    position: absolute;
    left: -1rem;
    bottom: 1rem;
    height: 100%;
    z-index: -1;
}
.testimonials-image .testimonial-back-box{
    width: 17rem;
    height: 100%;
    background: var(--planCard);
    position: absolute;
    top: 1rem;
    right: -1rem;
    z-index: -1;
}
@media only screen and (max-width: 768px)
{
    .testimonials-section{
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;
        text-align: center;
    }
    .testimonials-section .section-title{
        margin: 0px;
    }
    .testimonial-review{
        font-size: small;
        line-height: 25px;
        text-align: center;
    }
    .testimonial-footer{
        text-align: center;
        margin-top: 0px;
    }
    .testimonial-name{
        font-size: x-small;
    }
    .testimonial-status{
        font-size: x-small;
    }
    .testimonials-right{
        flex-direction: column;
    }
    .testimonials-arrow{
        position: relative;
        top: 1rem;
        left: 0rem;
    }
    .testimonials-arrow img{
        width: 1rem;
    }
    .testimonials-image{
        position: relative;
        left: 0rem;
        top: 4rem;
    }
    .testimonials-image img{
        width: 15rem;  
        height: 20rem;  
        z-index: 2;
    }
    .testimonials-image .testimonial-border{
        width: 12rem;
        height: 15rem;
        left: 0.5rem;
    }
    .testimonials-image .testimonial-back-box{
        width: 12rem;
        height: 15rem;
        right: 0.5rem;
    }
    .testimonial-border, .testimonial-back-box{
        position: relative;
        display: none;
    }
}